import { handleDefaultModal } from './utils';
import locale from './translations/ru.json';

const defaultId = 'default';
export const modalSelector = '#form-modal';
const formSelector = '#demonstration-form-modal';

const modal = document.querySelector(modalSelector);
const header = modal ? modal.querySelector('[data-modal-header]') : null;
const text = modal ? modal.querySelector('[data-modal-text]') : null;
const buttonText = modal ? modal.querySelector('[data-modal-button]') : null;
const modalHeaderInput = modal ? modal.querySelector('input[name=modal-header]') : null;

const demonstrationForm = document.querySelector(formSelector);
const nameField = demonstrationForm ? demonstrationForm.querySelector('[name=name]') : null;
const emailField = demonstrationForm ? demonstrationForm.querySelector('[name=email]') : null;
const phoneField = demonstrationForm ? demonstrationForm.querySelector('[name=phone]') : null;
const messageField = demonstrationForm ? demonstrationForm.querySelector('[name=message]') : null;

const content = {
  default: {
    header: locale['formsModal.default.header'],
    text: '',
    button: locale['formsModal.default.button'],
  },
  get_price: {
    header: locale['formsModal.getPrice.header'],
    text: locale['formsModal.getPrice.text'],
    button: locale['formsModal.getPrice.button'],
  },
  get_demo: {
    header: locale['formsModal.getDemo.header'],
    text: locale['formsModal.getDemo.text'],
    button: locale['formsModal.getDemo.button'],
  },
  get_free: {
    header: locale['formsModal.getFree.header'],
    text: locale['formsModal.getFree.text'],
    button: locale['formsModal.getFree.button'],
  },
  get_offer: {
    header: locale['formsModal.getOffer.header'],
    text: locale['formsModal.getOffer.text'],
    button: locale['formsModal.getOffer.button'],
  },
  get_presentation: {
    header: locale['formsModal.getPresentation.header'],
    text: locale['formsModal.getPresentation.text'],
    button: locale['formsModal.getPresentation.button'],
  },
};

const onClickShowModal = (e) => {
  resetForm();
  changeContent(
    content[
      e.currentTarget && e.currentTarget.dataset.modalForm && content[e.currentTarget.dataset.modalForm]
        ? e.currentTarget.dataset.modalForm
        : defaultId
    ],
  );

  handleDefaultModal(modalSelector, () => {
    resetForm();
  });
};

const changeContent = (contentItem) => {
  if (header) {
    header.innerHTML = contentItem.header;
  }
  if (text) {
    text.innerHTML = contentItem.text;
  }
  if (buttonText) {
    buttonText.innerHTML = contentItem.button;
  }
  if (modalHeaderInput) {
    modalHeaderInput.value = contentItem.header;
  }
};

const resetForm = () => {
  if (demonstrationForm) {
    demonstrationForm.classList.remove('success');
    demonstrationForm.reset();
  }
  if (nameField) {
    nameField.parentElement.classList.remove('error');
    nameField.disabled = false;
  }
  if (emailField) {
    emailField.parentElement.classList.remove('error');
    emailField.disabled = false;
  }
  if (phoneField) {
    phoneField.parentElement.classList.remove('error');
    phoneField.disabled = false;
  }
  if (messageField) {
    messageField.disabled = false;
  }
};

const modalButtons = document.querySelectorAll('[data-modal-form]:not([data-modal-form="get_calc_roi"])');

if (modalButtons) {
  modalButtons.forEach((btn) => btn.addEventListener('click', onClickShowModal));
}
